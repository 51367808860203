<template lang="pug">
.widget
  .widget-header
    h1.title {{ ticket.id ? '' : 'New' }} Support Ticket {{ ticket.id }}
  .widget-body
    div(v-if="!ticket.id")
      form(@submit.prevent="() => submit()")
        .form-inline.ov-filters.mb-4
          b-form-group
            select-advertiser(v-model="advertiserId")
          b-form-group
            select-offer(v-model="offerId")
          b-form-group
            select-publisher(v-model="publisherId")
          b-form-group
            select-placement(v-model="placementId")
          b-form-group(label="Type")
            select.form-control(v-model="type")
              option(v-for="o in typeOptions", :value="o.value") {{ o.text }}
          b-form-group(label="Topic")
            select.form-control(v-model="topic")
              option(v-for="o in topicOptions", :value="o.value") {{ o.text }}
          b-form-group(label="Priority")
            select.form-control(v-model="priority")
              option(v-for="o in priorityOptions", :value="o.value") {{ o.text }}
        b-form-group(label="Title")
          b-form-input(v-model="ticket.title")
        b-form-group(label="Description")
          editor(v-model="text")
        b-form-group(label="Attachments (pictures can be right into text editor)")
          doc-list(:files="attachments")
        b-button(type="submit" variant="primary") Create ticket

    div(v-if="ticket.id")
      h1.title {{ ticket.title }}
      .row.mb-2(v-for="message of messages")
        div(class="col-12")
          .message-info.mb-4
            span.mr-2 {{ message.createdAt | formatDateWithTime }}
            span.font-weight-bold(:style="fromClient(message) ? { color: '#e27575' } : { color: '#4c89ca' }") {{ fromClient(message) ? message.user.name : 'Support' }}
          .message-content(v-html="message.content")
          .message-attachments(v-if="message.attachments.length")
            p.font-weight-bold.m-0 Attachments:
            div(v-for="a of message.attachments")
              a(:href="a.url" target="_blank") {{ a.fileName }}
          hr

      form(@submit.prevent="() => submit()")
        b-form-group
          editor(v-model="text")
        b-form-group(label="Attachments")
          doc-list(:files="attachments")
        b-button.mr-2(v-if="!isClosed" type="button" variant="secondary" v-on:click="() => submit(true)") Reply and close
        b-button(type="submit" variant="primary") {{ isClosed ? 'Reopen' : 'Reply' }}
</template>

<style lang="scss">
.message-content {
  word-wrap: break-word;

  p {
    font-size: 1rem;
  }
}

.message-info {
  font-size: 10px;
  color: #999;
}
</style>

<script>
import Vue from 'vue';

const Types = {
  Bug: 'bug',
  Feature: 'feature',
  Call: 'call',
};
const Priorities = {
  Low: 'low',
  Medium: 'medium',
  High: 'high',
};

export default {
  name: 'SupportForm',
  async beforeRouteEnter(to, from, next) {
    if (to.name === 'support-ticket-add') {
      return next();
    }

    const entityId = to.params.id;
    try {
      const resp = await Vue.ovData.support.get(entityId);
      next((vm) => {
        vm.messages = resp.messages;
        delete resp.messages;
        vm.ticket = resp;
        return vm;
      });
    } catch (e) {
      Vue.ovNotify.error('Failed to get ticket');
      next();
    }
  },
  updated() {
    // Get all images elements from .message-content and add a element with the same href as src
    const messageContent = document.querySelectorAll('.message-content');
    for (const message of Array.from(messageContent)) {
      const images = message.querySelectorAll('img');
      for (const image of Array.from(images)) {
        const parent = image.parentNode;
        if (!parent) {
          continue;
        }

        if (parent.nodeName === 'A') {
          continue;
        }

        const a = document.createElement('a');
        a.href = image.src;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        image.setAttribute('style', 'max-width: 200px; max-height: 200px;');
        a.appendChild(image.cloneNode(true));
        image.parentNode.replaceChild(a, image);
      }
    }
  },
  data() {
    return {
      ticket: {},
      messages: [],
      text: '',
      advertiserId: [],
      publisherId: [],
      offerId: [],
      placementId: [],
      type: Types.Bug,
      topic: '0',
      priority: Priorities.Low,
      typeOptions: [
        { value: Types.Bug, text: 'Bug' },
        { value: Types.Feature, text: 'Feature' },
        { value: Types.Call, text: 'Call with the tech team' },
      ],
      topicOptions: Object.entries(Vue.ovData.support.Topics).map(([value, text]) => ({
        value,
        text,
      })),
      priorityOptions: [
        { value: Priorities.Low, text: 'Low' },
        { value: Priorities.Medium, text: 'Medium' },
        { value: Priorities.High, text: 'High' },
      ],
      attachments: [],
    };
  },
  computed: {
    isClosed() {
      return this.ticket.generalStatus === 'closed';
    },
  },
  mounted() {
    const ticketId = this.$route.params.id;
    if (!ticketId) {
      return;
    }
    setTimeout(async () => {
      await Vue.ovData.support.seen(ticketId);
    }, 3000);
  },
  methods: {
    async loadData() {
      const ticketId = this.$route.params.id;
      const resp = await Vue.ovData.support.get(ticketId);
      this.messages = resp.messages;
      delete resp.messages;
      this.ticket = resp;
    },
    async submit(shouldClose = false) {
      if (!this.text && !shouldClose && !this.isClosed) {
        return;
      }

      const attachments = this.attachments.map((a) => ({ fileName: a.name, url: a.full_path }));

      // Create New ticket and return
      if (!this.ticket.id) {
        const advertiserId = this.advertiserId.length ? this.advertiserId[0].v : null;
        const publisherId = this.publisherId.length ? this.publisherId[0].v : null;
        const offerId = this.offerId.length ? this.offerId[0].id : null;
        const placementId = this.placementId.length ? this.placementId[0].id : null;
        await Vue.ovData.support.create({
          title: this.ticket.title,
          text: this.text,
          advertiserId: advertiserId,
          publisherId: publisherId,
          offerId: offerId,
          placementId: placementId,
          type: this.type,
          topic: this.topic,
          priority: this.priority,
          attachments,
        });
        this.$router.push({ name: 'support-tickets-list' });
        return;
      }

      // Reply on existing ticket
      await Vue.ovData.support.reply({
        ticketId: this.ticket.id,
        text: this.text,
        generalStatus: shouldClose ? 'closed' : 'open',
        attachments,
      });

      await this.loadData();
      this.text = '';
      this.attachments = [];
    },
    fromClient(message) {
      return message.from === 'client';
    },
  },
};
</script>
